"use strict";

function fadeOut(el){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
};

function fadeIn(el, display){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    if (!((val += .1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
};

//

function closeModal() {
	fadeOut(document.querySelector(".modal"));
}

function showModal() {
	fadeIn(document.querySelector(".modal"));
}

let navButton = document.querySelector(".js-triggerMenu");

navButton.addEventListener("click", toggleNavigation);

function toggleNavigation(){
    if(!document.body.hasAttribute("data-menu")){
        document.body.setAttribute("data-menu", true);
    }else{
        document.body.removeAttribute("data-menu");
    }
}

try {
document.querySelector(".modal").addEventListener("click", closeModal);
document.querySelector(".modal .content .close").addEventListener("click", closeModal);
document.getElementById('confirm-assoc').addEventListener("click",showModal);
} catch(e) {}

function addFilters() {
	document.querySelector("#filters").submit();
}

var selects = document.querySelectorAll('#filters select'), i;
for (i = 0; i < selects.length; ++i) {
  selects[i].addEventListener("change", addFilters);
  
  // Adapt selectbox to selection option
  let value = selects[i].options[selects[i].selectedIndex].innerHTML;
  var span = document.createElement("span");
  span.innerHTML = value;
  document.querySelector("body").appendChild(span); 
  let textSize = span.offsetWidth;
  span.parentNode.removeChild(span);
  selects[i].style.width = (textSize+30) + "px";
}